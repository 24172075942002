import type { MenuProps } from 'antd/es'
import type { FC } from 'react'
import { useTheme } from 'styled-components'

import type { IMenuItem } from '@redux/features/header/types/menu'

import Divider from '@base/Divider'
import Dropdown from '@base/Dropdown'
import { rootDropdownClassName } from '@constants/dropdown/dropdown'

import MenuItem from './components/MenuItem'
import { DropdownContainer, NavSelectorWrapper } from './styles'

type Props = {
  menuData: IMenuItem[]
}

const ITEM_HEIGHT = 52

const getItem = (item: IMenuItem): MenuProps['items'] | undefined =>
  item.below?.map((belowItem, index) => ({
    children: belowItem.below ? getItem(belowItem) : undefined,
    key: belowItem.key,
    label: <MenuItem label={belowItem.title} link={belowItem.relative} />,
    popupOffset: [5, index * -ITEM_HEIGHT - 18],
  }))

export const NavSelectors: FC<Props> = ({ menuData }) => {
  const theme = useTheme()

  return (
    <NavSelectorWrapper>
      {menuData.map(menuItem => {
        const withMenu = !!menuItem.below

        return (
          <DropdownContainer id={menuItem.key} key={menuItem.key}>
            <Dropdown
              menu={{
                getPopupContainer: () => document.getElementById(menuItem.key) || document.body,
                items: getItem(menuItem),
                rootClassName: 'navselectors-submenu',
              }}
              dropdownRender={node => (withMenu ? node : '')}
              getPopupContainer={() => document.getElementById(menuItem.key) || document.body}
              href={menuItem.relative}
              label={menuItem.title}
              mouseEnterDelay={0}
              overlayStyle={{ animationDuration: '0.1s' }}
              placement="bottomRight"
              rootClassName={`${rootDropdownClassName} ${menuItem.below?.[0].below ? 'with-submenu' : ''}`}
              withMenu={withMenu}
            />
          </DropdownContainer>
        )
      })}
      <Divider
        dashed={false}
        style={{ borderColor: theme?.colors.fontSecondaryLight, height: '40px', margin: '12px 40px 12px 0' }}
        type="vertical"
      />
    </NavSelectorWrapper>
  )
}
