import type { FC } from 'react'

import Title from '@typography/Title'

import { MenuItemWrapper } from './styles'

type Props = {
  label: string
  link?: string
}

export const MenuItem: FC<Props> = ({ label, link }) => {
  return (
    <MenuItemWrapper href={link || ''}>
      <Title level={6}>{label}</Title>
    </MenuItemWrapper>
  )
}
